<header>
    <nav class="navbar navbar-expand-md navbar-toggleable-md bg-info-subtle">
        <div class="container">
            <a class="navbar-brand" [routerLink]="['/']">TheFortress</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
                aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse collapse d-md-inline-flex justify-content-between"
                [ngClass]="{ show: isExpanded }">
                <ul class="navbar-nav">
                    <li *ngFor="let item of navItems" class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link" [routerLink]="[item.routerLink]">{{item.name}} </a>
                    </li>
                </ul>
                <app-auth-button></app-auth-button>
            </div>
        </div>
    </nav>
</header>