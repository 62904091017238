<section class="intro">
    <div class="jumbotron splash fade-in-image" [ngStyle]="{'background-image': 'url(' + bgUrl + ') '}">
        <div class="container-fluid">
            <div class="row text-center">
                <div class="col-12">
                    <div class="">
                        <h1 class="splash-text splash-title">{{title}}</h1>
                    </div>
                    <p class="splash-text" style="font-size: 1.05rem;">{{subtitle}}</p>
                </div>

            </div>
        </div>
    </div>
</section>