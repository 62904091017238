<div class="spinner-wrapper">
    <section class="wrapper dark">
        <div class="spinner">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
    </section>

</div>