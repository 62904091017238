<ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
    <ul class="navbar-nav">
        <li *ngIf="auth.isAdmin()" class="nav-item">
            <a href="javascript:void(0)" class="nav-link text-light" [routerLink]="['/admin']">Admin Panel</a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-light" [routerLink]="['/auth/account/info']" >Hello, {{auth.getName()}}</a>
        </li>
        <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link text-light" (click)="auth.logout()">Logout</a>
        </li>

    </ul>
</ng-container>

<ng-template #loggedOut>
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link text-light" routerLink="/auth/login">Login</a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-light" routerLink="/auth/register">Register</a>
        </li>
    </ul>
</ng-template>