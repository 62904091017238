<body>
    <div>
        <app-nav-menu></app-nav-menu>

        <div>
            <router-outlet></router-outlet>
        </div>

    </div>
    <footer>
    <app-footer></app-footer>
  </footer>
</body>