import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shared-splash',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-splash.component.html',
  styleUrl: './shared-splash.component.css'
})
export class SharedSplashComponent {
  @Input() bgUrl;
  @Input() title;
  @Input() subtitle;

}
