import { SweetAlertOptions } from "sweetalert2";

export class SwalConfs {

    static getErrorToast(title?: string, text?: string): SweetAlertOptions {
        return { 
            toast: true, 
            position: 'top-right', 
            title: title, 
            icon: 'error', 
            iconColor: 'white',
            customClass : {
                popup: 'colored-toast'
            },
            text: text,
            timer: 10000,
            timerProgressBar: true
        };
    }

    static getSuccessToast(title?: string, text?: string, timeout = 5000): SweetAlertOptions {
        return { 
            toast: true, 
            position: 'top-right', 
            title: title, 
            icon: 'error', 
            iconColor: 'white',
            customClass : {
                popup: 'colored-toast'
            },
            text: text,
            timer: timeout,
            timerProgressBar: true
        };
    }
}